import React, { useState } from "react";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
import "./Select.css";

export default function Select (props)
{
    const [helpHover, setHelpHover] = useState(false);

    return (
        <div className="select">
            {
                props.label &&
                <div className="label">
                    <span className="textLabel">{props.label}</span>
                    {
                        props.help &&
                        <div className="help" 
                            onMouseOver={() => setHelpHover(true)}  
                            onMouseLeave={() => setHelpHover(false)}
                        >
                            <IoIosHelpCircleOutline className="icon" />
                            {helpHover && <span className="message">{props.help}</span>}                        
                        </div>
                    }
                </div>
            }
            <div className="selector">
                <select 
                    onChange={(e) => props.onChange(e.target.value)}
                    value={`${props?.checked}`}
                >
                    {
                        props?.options.map((o, i) => {
                            return <option 
                                        key={i} 
                                        value={o.value}
                                    >
                                        {o.label}
                                    </option>
                        })
                    }
                </select>
                <MdKeyboardArrowDown className="icon"/>
            </div>
        </div>
    )
}