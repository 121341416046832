import "../components/Screens.css";
import React, { Component } from "react"
import { MdModeEdit } from "react-icons/md";
import { FiTrash2 } from "react-icons/fi";

import Select from "../components/Select";
import BoxScreen from "../components/BoxScreen";
import Input from "../components/Input";
import Box from "../components/Box";
import Message from "../components/Message";
import MessageConfirmation from "../components/MessageConfirmation";

import API from "../api";
import Button from "../components/Button";

const MAXIMUM_QUESTION_PER_PAGE = 20;

const KNOWLEDGE_AREA = [
    {
        label: "competência C1",
        value: 1
    },
    {
        label: "competência C2",
        value: 2
    },
    {
        label: "competência C3",
        value: 3
    },
    {
        label: "competência C4",
        value: 4
    },
    {
        label: "competência C5",
        value: 5
    },
];

const INPUTS_DEFAULT = {
    // start form fiels
    inputNumber: "", //default is empty string
    inputTitle: "", //default is empty string
    inputDescription: "", //default is empty string
    inputAlternativeA: "", //default is empty string
    inputAlternativeB: "", //default is empty string
    inputAlternativeC: "", //default is empty string
    inputAlternativeD: "", //default is empty string
    inputAlternativeE: "", //default is empty string
    inputAlternativeCorrect: "A", //default is "A"
    inputOptionsCorrect: [
        {
            label: "A",
            value: "A"
        },
        {
            label: "B",
            value: "B"
        },
        {
            label: "C",
            value: "C"
        },
        {
            label: "D",
            value: "D"
        },
        {
            label: "E",
            value: "E"
        },
    ],
    inputKnowledgeArea: "C1", //default is "C1"
    inputOptionsKnowledgeArea: [
        {
            label: "competência C1",
            value: "C1"
        },
        {
            label: "competência C2",
            value: "C2"
        },
        {
            label: "competência C3",
            value: "C3"
        },
        {
            label: "competência C4",
            value: "C4"
        },
        {
            label: "competência C5",
            value: "C5"
        },
    ],
    inputImage: null, //default is null
    inputImageURL: null, //default is null
    // ends form fields
}

const ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT = {
    messageTypeConfirmation: "information",
    messageShowConfirmation: false,
    messageConfirmation: "",
    statusConfirmation: false,
    nameFunctionYesModalConfirmation: "",
    objectConfirmation: {},
}

const initialState = {

    token: "",
    allQuestions: [],
    allQuestionsNotFiltered: [],
    amountQuestionsInDataBase: 0,
    amountQuestionsInSelectKnowledgeArea: 0,
    saving: false,
    modeUpdate: false,

    ...INPUTS_DEFAULT,
    inputImageKey: 0,

    // start message modal
    message: "",
    messageType: "information",
    messageShow: false,
    // ends message modal

    // start filter area
    number: "",
    currentPage: 0,
    allPages: [
        {
            label: "página 1",
            value: 0
        }
    ],
    currentSort: 0,
    allWaysSort: [
        {
            label: "crescente",
            value: 0,
        },
        {
            label: "decrescente",
            value: 1,
        },
    ],
    currentKnowledgeArea: 0,
    allKnowledgeArea: [
        {
            label: "todas competências",
            value: 0
        },
        ...KNOWLEDGE_AREA
    ],
    // ends filter area

    ...ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT
}
export default class ScreenA extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    { 
        let token = await this.getToken();
        if (token) { await this.startScreen(); };
    }

    componentDidUpdate = async (prevProps) =>
    {
        let token = await this.getToken();

        // console.log("SCREEN A this.props: ", this.props);

        if (token && this.state.token === "")
        {
            await this.setState({token});
            await this.startScreen();
        }
    }

    startScreen = async () =>
    {
        await this.getAllQuestions();
    }

    getToken = async () =>
    {
        return this.props.token;
    }

    getAllQuestions = async () =>
    {
        let token = await this.getToken();
        let api = new API();
        let allQuestions = [];
        let allQuestionsNotFiltered = [];
        let response = await api.question().getAll(token);
        
        if (response.code === 200)
        {
            let data = response.data;

            Object.keys(data).forEach(k => {
                let question = data[k];
                allQuestions.push({...question});
            });
        }
        else
        {
            await this.message("error", response.message);
        }

        allQuestionsNotFiltered = allQuestions;
        await this.setState({allQuestions, allQuestionsNotFiltered, amountQuestionsInDataBase: allQuestions.length});
        await this.setAllQuestionPages(allQuestionsNotFiltered);
        await this.applyFilters(allQuestionsNotFiltered);
    }

    setAllQuestionPages = async (allQuestionsNotFiltered) =>
    {
        let allPages = [];
        let amountQuestions = allQuestionsNotFiltered.length;
        let amountPages = Math.ceil(amountQuestions / MAXIMUM_QUESTION_PER_PAGE);

        // setting all the pages for optionsChoosePage...
        for (let i = 0; i < amountPages; i++) 
        {
            allPages.push(
                {
                    label: `página ${i + 1}`,
                    value: i
                }
            );
        }

        await this.setState({allPages});
    }

    applyFilters = async (allQuestionsNotFiltered) =>
    {
        let currentKnowledgeArea = await this.state.currentKnowledgeArea;
        let currentPage = await this.state.currentPage;
        let currentSort = await this.state.currentSort;
        let number = await this.state.number;
        let allQuestions = [];
        let filterByNumber = false;

        currentPage = parseInt(currentPage);
        currentKnowledgeArea = parseInt(currentKnowledgeArea);
        currentSort = parseInt(currentSort);

        // start filter by question number.
        if (number.toString().trim().length >= 6)
        {
            filterByNumber = true;
            allQuestions = allQuestionsNotFiltered.filter(q => (q.question_number) === parseInt(number));
            await this.setState({currentKnowledgeArea: 0});
        }
        // ends filter by question number.

        if (!filterByNumber)
        {
            // start filter by knowledge area.
            if (currentKnowledgeArea !== 0)
            {
                allQuestionsNotFiltered = allQuestionsNotFiltered.filter(q => q.knowledge_area === `C${currentKnowledgeArea}`);
            }
            // ends filter by knowledge area.
            
            // start pagination.
            let start = (currentPage * MAXIMUM_QUESTION_PER_PAGE);
            let end = (start + MAXIMUM_QUESTION_PER_PAGE);
            allQuestions = allQuestionsNotFiltered.slice(start, end);
            // ends pagination.
    
            // start sort.
            if (currentSort === 0) //ASC
            {
                allQuestions = allQuestions.sort((a, b) => {
                    if (a.question_number > b.question_number) { return 1; }
                    if (a.question_number < b.question_number) { return -1; }
                    return 0;
                });
            }
            else //ASC
            {
                allQuestions = allQuestions.sort((a, b) => {
                    if (a.question_number > b.question_number) { return -1; }
                    if (a.question_number < b.question_number) { return 1; }
                    return 0;
                });
            }
            // ends sort.
        }

        await this.setAllQuestionPages(filterByNumber ? allQuestions : allQuestionsNotFiltered);
        await this.setState({allQuestions, amountQuestionsInSelectKnowledgeArea: allQuestionsNotFiltered.length});
    }

    changeImage = async (event) => 
    {
        await this.setState({
            inputImageURL: null,
            inputImage: null
        });

        if (event.target.files.length > 0)
        {
            let file = event.target.files[0]
            let imageURL = URL.createObjectURL(event.target.files[0]);
            await this.setState({
                inputImageURL: imageURL,
                inputImage: file
            });
        }
    }

    message = async (type, message) =>
    {
        await this.setState(
            {
                messageShow: true,
                messageType: type,
                message: message
            }
        );
    }

    setAllInputsAsDefault = async () =>
    {
        await this.setState(
            {
                ...INPUTS_DEFAULT,
                inputImageKey: this.getRandomKey()
            }
        );
    }

    isValidQuestionNumber = async (inputNumber) =>
    {
        let allQuestionsNotFiltered = await this.state.allQuestionsNotFiltered;
        let modeUpdate = await this.state.modeUpdate;
        let inputNumberIsExists = false;
        let status = {message: "", valid: true};

        inputNumber = parseInt(inputNumber);
        inputNumber = (inputNumber > 200000) ? inputNumber : false;

        if (!Number.isInteger(inputNumber) || inputNumber.toString().length !== 6)
        {
            status.message = "Opsssss, parece que o número da questão é inválido.";
            status.valid = false;
        }

        inputNumberIsExists = allQuestionsNotFiltered.filter(q => q.question_number === inputNumber).length > 0;
        
        if (inputNumberIsExists && !modeUpdate) 
        {
            status.message = "Opsssss, esse número de questão já existe.";
            status.valid = false;
        }

        return status;
    }

    getRandomKey = () =>
    {
        return new Date().getTime();
    }

    save = async () =>
    {
        let inputNumber = await this.state.inputNumber;
        let inputTitle = await this.state.inputTitle; //INFORMATION: optional field
        let inputDescription = await this.state.inputDescription;
        let inputAlternativeA = await this.state.inputAlternativeA;
        let inputAlternativeB = await this.state.inputAlternativeB;
        let inputAlternativeC = await this.state.inputAlternativeC;
        let inputAlternativeD = await this.state.inputAlternativeD;
        let inputAlternativeE = await this.state.inputAlternativeE;
        let inputAlternativeCorrect = await this.state.inputAlternativeCorrect;
        let inputKnowledgeArea = await this.state.inputKnowledgeArea;
        let inputImage = await this.state.inputImage; //INFORMATION: optional field 
        let inputImageURL = await this.state.inputImageURL;
        let modeUpdate = await this.state.modeUpdate;
        let inputsValid = true;

        let numberValid = await this.isValidQuestionNumber(inputNumber);

        if (!numberValid.valid)
        {
            this.message("error", `${numberValid.message}`);
            return;
        }

        if 
        (
            inputNumber.toString().trim() === "" ||
            inputDescription.toString().trim() === "" ||
            inputAlternativeA.toString().trim() === "" ||
            inputAlternativeB.toString().trim() === "" ||
            inputAlternativeC.toString().trim() === "" ||
            inputAlternativeD.toString().trim() === "" ||
            inputAlternativeE.toString().trim() === "" ||
            inputAlternativeCorrect.toString().trim() === "" ||
            inputKnowledgeArea.toString().trim() === ""
        ) 
        { inputsValid = false; }

        if (!inputsValid)
        {
            this.message("error", "Opsssss, preencha os campos corretamente.");
            return;
        }

        if (!this.state.saving)
        {
            let question = {};

            let api = new API();

            if (modeUpdate)
            {
                let token = await this.getToken();
                let question = { question_number: parseInt(inputNumber) }

                if (inputTitle.toString().trim() === "")
                {
                    await api.question().removeProperty(question, "title", token); //forcing reset (because title and image are optional fields)
                }
                
                if (inputImageURL === null)
                {
                    await api.question().removeProperty(question, "image", token); //forcing reset (because title and image are optional fields)
                }
            }

            if (inputImage !== null) //INFORMATION: optional field.
            {
                this.setState({saving: true});
                this.message("information", "Aguarde; estamos fazendo o upload da imagem...");
                
                let folder = "questions";
                let fileName = `q${inputNumber}.jpg`;
                let resultUpload = await api.general().uploadFile(inputImage, fileName, folder);

                if (resultUpload.code !== 200)
                {
                    await this.setState({saving: false, modeUpdate: false});
                    await this.setAllInputsAsDefault();
                    await this.message("error", resultUpload.message);
                    return;
                }

                question.image = `q${inputNumber}`;
            }

            if (inputTitle) { question.title = inputTitle; } //INFORMATION: optional field.

            question.alternative1 = inputAlternativeA;
            question.alternative2 = inputAlternativeB;
            question.alternative3 = inputAlternativeC;
            question.alternative4 = inputAlternativeD;
            question.alternative5 = inputAlternativeE;
            question.correct = inputAlternativeCorrect;
            question.description = inputDescription;
            question.knowledge_area = inputKnowledgeArea;
            question.question_number = parseInt(inputNumber);

            let token = await this.getToken();
            let resultQuestion = await api.question().create(question, token);;

            if (resultQuestion.code !== 200)
            {
                this.message("error", resultQuestion.message);
                return;
            }

            await this.getAllQuestions();
            await this.setState({saving: false, modeUpdate: false});
            await this.setAllInputsAsDefault();
            await this.message("success", "Questão cadastrada com sucesso!");
        }
        else
        {
            this.message("error", "Opsssss, aguarde o processo atual terminar!");
        }
    }

    onEdit = async (q) =>
    {
        let inputTitle = "";
        let inputImageURL = null;
        let inputNumber = q.question_number;
        let inputDescription = q.description;
        let inputAlternativeA = q.alternative1;
        let inputAlternativeB = q.alternative2;
        let inputAlternativeC = q.alternative3;
        let inputAlternativeD = q.alternative4;
        let inputAlternativeE = q.alternative5;
        let inputAlternativeCorrect = q.correct;
        let inputKnowledgeArea = q.knowledge_area;

        if (q?.title && q.title !== "") { inputTitle = q.title; }

        if (q?.image && q.image !== "")
        {
            inputImageURL = `https://firebasestorage.googleapis.com/v0/b/project-simulados-etec.appspot.com/o/main%2Fquestions%2F${q.image}.jpg?alt=media&token=`;
        }

        await this.setState({
            modeUpdate: true,
            inputImageURL,
            inputNumber,
            inputTitle,
            inputDescription,
            inputAlternativeA,
            inputAlternativeB,
            inputAlternativeC,
            inputAlternativeD,
            inputAlternativeE,
            inputAlternativeCorrect,
            inputKnowledgeArea,
        });
    }

    onDelete = async () =>
    {
        if (!this.state.saving)
        {
            let objectConfirmation = await this.state.objectConfirmation;
            let statusConfirmation = await this.state.statusConfirmation;

            if (statusConfirmation)
            {
                await this.setState({saving: true});

                let api = new API();
                let token = await this.getToken();
                let result = await api.question().remove(objectConfirmation.question_number, token);
                
                if (result.code === 200)
                {
                    this.message("success", `Questão ${objectConfirmation.question_number} excluída com sucesso!`);
                    await this.getAllQuestions();
                }
                else
                {
                    this.message("error", result.message);
                }
                
                await this.setState({...ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT, saving: false});
            }
            else
            {
                await this.setState({
                    messageTypeConfirmation: "warning",
                    messageShowConfirmation: true,
                    messageConfirmation: `Deseja realmente excluir a questão ${objectConfirmation.question_number}?`,
                    nameFunctionYesModalConfirmation: "onDelete"
                });
            }
        }
        else
        {
            this.message("error", "Opsssss, aguarde o processo atual terminar!");
        }
    }

    onClickYesModalConfirmation = async () =>
    {
        let fn = await this.state.nameFunctionYesModalConfirmation;
        let obj = await this.state.objectConfirmation;

        await this.setState({...ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT});
        await this.setState({statusConfirmation: true, objectConfirmation: obj}); //@OVERWRITE
        
        switch (fn)
        {
            case "onDelete":
            this.onDelete();
            break;         

            default:
                break;
        }
    }

    onClickNoModalConfirmation = async () =>
    {
        await this.setState({...ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT});
    }

    openImage = (urlImage) =>
    {
        window.open(urlImage);
    }

    render ()
    {
        return (
            <div className="screens">
                <Message
                    messagetype={this.state.messageType}
                    message={this.state.message}
                    onClose={() => this.setState({messageShow: false})}
                    show={this.state.messageShow}
                />
                <MessageConfirmation
                    show={this.state.messageShowConfirmation}
                    message={this.state.messageConfirmation}
                    onClose={() => this.onClickNoModalConfirmation()}
                    onClickNo={() => this.onClickNoModalConfirmation()}
                    onClickYes={() => this.onClickYesModalConfirmation()}
                    messageType={this.state.messageTypeConfirmation}
                />
                <div className="d-block d-md-none text-center bg-warning p-2 rounded">
                    <span className="text-light">Aviso: a largura mínima para visualizar esse painel é de 768px (tablet)! Se você estiver pelo celular, tente deitá-lo para visualizar.</span>
                </div>
                <div className="d-none d-md-block">
                    <BoxScreen title="Questões">
                        <Box subtitle={`${this.state.modeUpdate ? "Atualizar" : "Cadastrar"} Questão`}>
                            <div className="row no-gutters line">
                                <div className="col-3 column pr-1">
                                    <Input 
                                        label="Número"
                                        value={this.state.inputNumber}
                                        placeholder="número da questão"
                                        onChange={async (e) => {
                                            let number = e.target.value;
                                            await this.setState({inputNumber: number});
                                        }}
                                    />
                                </div>
                                <div className="col-9 column pl-1">
                                    <Input 
                                        label="Título (opcional)"
                                        value={this.state.inputTitle}
                                        placeholder="título da questão"
                                        onChange={async (e) => {
                                            let title = e.target.value;
                                            await this.setState({inputTitle: title});
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <Input 
                                        label="Descrição"
                                        value={this.state.inputDescription}
                                        placeholder="descrição da questão"
                                        onChange={async (e) => {
                                            let description = e.target.value;
                                            await this.setState({inputDescription: description});
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <Input 
                                        label="Alternativa A"
                                        value={this.state.inputAlternativeA}
                                        placeholder="alternativa A da questão"
                                        onChange={async (e) => {
                                            let alternativeA = e.target.value;
                                            await this.setState({inputAlternativeA: alternativeA});
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <Input 
                                        label="Alternativa B"
                                        value={this.state.inputAlternativeB}
                                        placeholder="alternativa B da questão"
                                        onChange={async (e) => {
                                            let alternativeB = e.target.value;
                                            await this.setState({inputAlternativeB: alternativeB});
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <Input 
                                        label="Alternativa C"
                                        value={this.state.inputAlternativeC}
                                        placeholder="alternativa C da questão"
                                        onChange={async (e) => {
                                            let alternativeC = e.target.value;
                                            await this.setState({inputAlternativeC: alternativeC});
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <Input 
                                        label="Alternativa D"
                                        value={this.state.inputAlternativeD}
                                        placeholder="alternativa D da questão"
                                        onChange={async (e) => {
                                            let alternativeD = e.target.value;
                                            await this.setState({inputAlternativeD: alternativeD});
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <Input 
                                        label="Alternativa E"
                                        value={this.state.inputAlternativeE}
                                        placeholder="alternativa E da questão"
                                        onChange={async (e) => {
                                            let alternativeE = e.target.value;
                                            await this.setState({inputAlternativeE: alternativeE});
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-6 column pr-1">
                                    <Select
                                        label="Alternativa Correta"
                                        checked={this.state.inputAlternativeCorrect}
                                        options={this.state.inputOptionsCorrect} 
                                        onChange={async (value) => {
                                            await this.setState({inputAlternativeCorrect: value});
                                        }} 
                                    />
                                </div>
                                <div className="col-6 column pl-1">
                                    <Select
                                        label="Competência"
                                        checked={this.state.inputKnowledgeArea}
                                        options={this.state.inputOptionsKnowledgeArea} 
                                        onChange={async (value) => {
                                            await this.setState({inputKnowledgeArea: value});
                                        }} 
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <Input
                                        label="Imagem (opcional)"
                                        type="file"
                                        onChange={(e) => this.changeImage(e)}
                                        accept=".jpeg, .jpg"
                                        key={this.state.inputImageKey}
                                    />
                                </div>
                            </div>
                            {
                                this.state.inputImageURL &&
                                <div className="row no-gutters line">
                                    <div className="col-12 column">
                                        <div className="imagePreview">
                                            <img 
                                                src={this.state.inputImageURL} 
                                                alt="Imagem da Questão"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row no-gutters line">
                                <div className="col-12 column pr-1 d-flex justify-content-center">
                                    <Button
                                        name={`${this.state.modeUpdate ? "atualizar" : "cadastrar"}`} 
                                        style={{"maxWidth": "200px"}}
                                        onClick={this.save} 
                                    />
                                </div>
                            </div>
                        </Box>
                        <Box subtitle="Todas Questões">
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <span>Total de questões: {this.state.amountQuestionsInDataBase}.</span><br />
                                    <span>Total de questões por página: {MAXIMUM_QUESTION_PER_PAGE}.</span><br />
                                    <span>Total de questões das competências selecionadas: {this.state.amountQuestionsInSelectKnowledgeArea}.</span><br />
                                </div>
                            </div>
                            <div className="row no-gutters line filters">
                                <div className="col-3 column filters pr-1">
                                    <Input 
                                        value={this.state.number}
                                        placeholder="número da questão"
                                        onChange={async (e) => {
                                            let number = e.target.value;
                                            await this.setState({number});
                                            let allQuestionsNotFiltered = await this.state.allQuestionsNotFiltered;
                                            await this.applyFilters(allQuestionsNotFiltered);
                                        }}
                                    />
                                </div>
                                <div className="col-3 column filters pr-1 pl-1">
                                    <Select
                                        checked={this.state.currentSort}
                                        options={this.state.allWaysSort} 
                                        onChange={async (value) => {
                                            await this.setState({currentSort: value});
                                            let allQuestionsNotFiltered = await this.state.allQuestionsNotFiltered;
                                            await this.applyFilters(allQuestionsNotFiltered);
                                        }} 
                                    />
                                </div>
                                <div className="col-3 column filters pr-1 pl-1">
                                    <Select
                                        checked={this.state.currentKnowledgeArea}
                                        options={this.state.allKnowledgeArea} 
                                        onChange={async (value) => {
                                            await this.setState({currentKnowledgeArea: value});
                                            let allQuestionsNotFiltered = await this.state.allQuestionsNotFiltered;
                                            await this.applyFilters(allQuestionsNotFiltered);
                                        }} 
                                    />
                                </div>
                                <div className="col-3 column filters pl-1">
                                    <Select
                                        checked={this.state.currentPage}
                                        options={this.state.allPages} 
                                        onChange={async (value) => {
                                            await this.setState({currentPage: value});
                                            let allQuestionsNotFiltered = await this.state.allQuestionsNotFiltered;
                                            await this.applyFilters(allQuestionsNotFiltered);
                                        }} 
                                    />
                                </div>
                            </div>
                            <div className="table">
                                <div className="row no-gutters w-100 line head">
                                    <div className="col-1 column">
                                        <span>Número</span>
                                    </div>
                                    <div className="col-1 column">
                                        <span>Imagem</span>
                                    </div>
                                    <div className="col-2 column">
                                        <span>Título</span>
                                    </div>
                                    <div className="col-2 column">
                                        <span>Descrição</span>
                                    </div>
                                    <div className="col-2 column">
                                        <span>Alternativas</span>
                                    </div>
                                    <div className="col-1 column">
                                        <span>Correta</span>
                                    </div>
                                    <div className="col-1 column">
                                        <span>Área</span>
                                    </div>
                                    <div className="col-2 column">
                                        <span>Ações</span>
                                    </div>
                                </div>
                                {
                                    this.state.allQuestions.map(q => {
                                        let urlImage = null;
                                        if (q?.image) 
                                        {
                                            urlImage = `https://firebasestorage.googleapis.com/v0/b/project-simulados-etec.appspot.com/o/main%2Fquestions%2F${q.image}.jpg?alt=media&token=`;
                                        }

                                        return (
                                            <div className="row no-gutters w-100 line body" key={`${q.question_number}`}>
                                                <div className="col-1 column">
                                                    <span>{q.question_number}</span>
                                                </div>
                                                <div className="col-1 column">
                                                    {
                                                        q?.image 
                                                        ?
                                                        <img 
                                                            src={urlImage} 
                                                            className="image img-fluid" 
                                                            alt="imagem"
                                                            key={this.getRandomKey()}
                                                            onClick={() => this.openImage(urlImage)}
                                                        />
                                                        : "[sem]"
                                                    }
                                                </div>
                                                <div className="col-2 column">
                                                    <div className="spanColumn">
                                                        <div className="spanColumnNoHover">
                                                            {q?.title ? q.title : "[questão sem título]"}
                                                        </div>
                                                        <div className="spanColumnHover">
                                                            {q?.title ? q.title : "[questão sem título]"}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-2 column">
                                                    <div className="spanColumn">
                                                        <div className="spanColumnNoHover">
                                                            {q?.description ? q.description : "[questão sem descrição]"}
                                                        </div>
                                                        <div className="spanColumnHover">
                                                            {q?.description ? q.description : "[questão sem descrição]"}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-2 column">
                                                    <div className="spanColumn">
                                                        <div className="spanColumnNoHover">
                                                            a) {q.alternative1}
                                                            b) {q.alternative2}
                                                            c) {q.alternative3}
                                                            d) {q.alternative4}
                                                            e) {q.alternative5}
                                                        </div>
                                                        <div className="spanColumnHover">
                                                            a) {q.alternative1} <br/>
                                                            b) {q.alternative2} <br/>
                                                            c) {q.alternative3} <br/>
                                                            d) {q.alternative4} <br/>
                                                            e) {q.alternative5}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-1 column">
                                                    <span>{q.correct}</span>
                                                </div>
                                                <div className="col-1 column">
                                                    <span>{q.knowledge_area}</span>
                                                </div>
                                                <div className="col-2 column">
                                                    <button className="btnOperation" onClick={() => this.onEdit(q)}>
                                                        <MdModeEdit className="iconOperation" />
                                                    </button>
                                                    <button 
                                                        className="btnOperation" 
                                                        onClick={async () => {
                                                            await this.setState({objectConfirmation: q});
                                                            await this.onDelete();
                                                        }}
                                                    >
                                                        <FiTrash2 className="iconOperation" />
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Box>
                    </BoxScreen>
                </div>
            </div>
        )
    }
}