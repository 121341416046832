import "./Anchor.css";

export default function Anchor (props)
{
    return (
        <button 
            className="anchor" 
            onClick={props.onClick}
            style={props?.styleAAnchor}
        >
            <span
                style={props?.styleLabel}
            >
                {props.label}
            </span>
        </button>
    )
}