import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import axios from "axios";
import { URL_BASE_DATABASE_FIREBASE } from "./constants";

axios.defaults.baseURL = URL_BASE_DATABASE_FIREBASE;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<React.StrictMode><App /></React.StrictMode>);