import "./Button.css";

export default function Button (props)  
{
    return (
        <button   
            className={`button ${props?.classadditional && props.classadditional}`}
            onClick={() => props.onClick()}
            style={{ 
                ...props?.style
            }} 
        >
            {props.name}
        </button>
    )
}