import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCvs_3SRpLABSozjPKQSp5fwwEGMm3oft8",
    authDomain: "project-simulados-etec.firebaseapp.com",
    databaseURL: "https://project-simulados-etec-default-rtdb.firebaseio.com",
    projectId: "project-simulados-etec",
    storageBucket: "project-simulados-etec.appspot.com",
    messagingSenderId: "900338597127",
    appId: "1:900338597127:web:16cd5e6d21e2dec23ed9a2"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const auth  = firebase.auth();

export { storage, auth, firebase as default };