import React, { Component, Fragment } from "react";
import { NAME_APPLICATION } from "./constants";

import Panel from "./Panel";
import Auth from "./views/Auth";
import Message from "./components/Message";

const initialState = {
    hasUserLogged: false,

    // start message modal
    message: "",
    messageType: "information",
    messageShow: false,
    // ends message modal
}

export default class App extends Component {
    
    state = {...initialState}

    componentDidMount = async () =>
    {
        await this.hasUserLogged();
    }

    hasUserLogged = async () =>
    {
        let user = await localStorage.getItem(`USER_${NAME_APPLICATION}`);
        user = JSON.parse(user);

        if (user !== null && user.token)
        {
            if (user.token !== "") { await this.setState({hasUserLogged: true}); }
        }
        else
        {
            await this.setState({hasUserLogged: false});
        }
    }
    
    login = () =>
    {
        this.setState({hasUserLogged: true});
    }
    
    logout = async (message = null) =>
    {
        await localStorage.setItem(`USER_${NAME_APPLICATION}`, null);
        this.setState({hasUserLogged: false});

        if (message)
        {
            this.message("information", message);
        }
    }

    message = async (type, message) =>
    {
        await this.setState(
            {
                messageShow: true,
                messageType: type,
                message: message
            }
        );
    }

    render ()
    {
        return (
            <Fragment>
                <Message
                    messagetype={this.state.messageType}
                    message={this.state.message}
                    onClose={() => this.setState({messageShow: false})}
                    show={this.state.messageShow}
                />
                {
                    this.state.hasUserLogged
                    ? 
                    <Panel onLogout={(message) => this.logout(message)}/>
                    : 
                    <Auth/>
                }
            </Fragment>
        )
    }
}