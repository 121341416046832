import "./Auth.css";
import { Component } from "react";
import { NAME_APPLICATION } from "../constants";
import brand from "../assets/images/brand.png";
import Input from "../components/Input";
import Button from "../components/Button";
import Anchor from "../components/Anchor";
import Message from "../components/Message";

import API from "../api";

const initialState = {
    // START MESSAGE MODAL
    message: "",
    messageType: "information",
    messageShow: false,
    // ENDS MESSAGE MODAL

    mail: "",
    password: "",
    file: null,
    mode: "login"
}

export default class Auth extends Component
{
    
    state = {...initialState}

    actionModeAuth = async () => 
    {
        if (this.state.mode === "login")
        {
            let mail = this.state.mail;
            let password = this.state.password;

            if 
            (
                mail.trim() === "" ||
                password.trim() === ""
            )
            {
                return await this.message("error", "Opsssss, parece que esse e-mail e/ou senha é(são) inválido(s). Preencha o campo e-mail e senha corretamente!");
            }
    
            let api = new API();
            let result = await api.user().login(mail, password);
            
            if (result.code === 200 && result.user.token !== "")
            {
                if (result.user.is_adm)
                {
                    let {name} = result.user;
                    let welcome = name.toString().split(" ")[0];
                    await this.message("success", `Seja bem-vindo(a), ${welcome}!`);
                    let seconds = ((new Date().getTime())/1000);
                    let user = {
                        ...result.user,
                        token_expires_at: seconds+3600,
                    };
                    await localStorage.setItem(`USER_${NAME_APPLICATION}`, JSON.stringify(user));
    
                    setTimeout(() => {
                        let url = window.location.href;
                        window.location.href = url;
                    }, 3000);
                    return;
                }
                else
                {
                    await this.message("error", "Opsssss, parece que tem alguma coisa errada. Contacte o administrador do sistema!");
                }
            }
            else
            {
                return await this.message("error", result.message);
            }
        }

        if (this.state.mode === "recovery")
        {
            let mail = this.state.mail;

            if (mail.trim() === "")
            {
                return await this.message("error", "Opsssss. Se deseja recuperar a senha, preencha o campo de e-mail corretamente!");
            }
    
            let api = new API();
            let result = await api.user().recovery(mail);
    
            if (result.code === 200)
            {
                return await this.message("success", `Se houver alguma conta com o e-mail "${mail}", você receberá um link para que possa alterar sua senha! Não esqueça de olhar a caixa de spam/lixeira, pois eventualmente poderá estar lá (por causa da imagem/logo)!`);
            }
            else
            {
                return await this.message("error", result.message);
            }
        }
    }

    message = async (type, message) =>
    {
        await this.setState(
            {
                messageShow: true,
                messageType: type,
                message: message
            }
        );
    }

    render ()
    {
        return (
            <div className="auth">
                <Message
                    messagetype={this.state.messageType}
                    message={this.state.message}
                    onClose={() => this.setState({messageShow: false})}
                    show={this.state.messageShow}
                />
                <div className="brand"> 
                    <img src={brand} alt="" />
                </div>
                <div className="container">  
                    <form className="form" onSubmit={e => e.preventDefault()} >  
                        <h3 className="title">
                            {this.state.mode === "recovery" ? "Recuperar Senha" : "Login"}
                        </h3>
                        <Input 
                            placeholder="email@dominio.com.br"
                            autoComplete="username"
                            onChange={(e) => this.setState({mail: e.target.value})}
                            value={this.state.mail}
                        />
                        {
                            this.state.mode !== "recovery" &&
                            <Input 
                                placeholder="******"
                                type="password" 
                                autoComplete="current-password" 
                                onChange={(e) => this.setState({password: e.target.value})}
                                value={this.state.password}
                            />
                        }
                        <Button 
                            name={this.state.mode === "recovery" ? "recuperar" : "entrar"} 
                            onClick={() => this.actionModeAuth()} 
                        />
                        <Anchor 
                            onClick={() => this.state.mode === "recovery" ? this.setState({mode: "login"}) : this.setState({mode: "recovery"})} 
                            label={this.state.mode === "recovery" ? "entrar" : "recuperar senha" }
                            styleLabel={{color: "var(--dark-dark)"}}
                        />
                    </form>
                </div>
                <Anchor 
                    onClick={() => window.open("https://www.ewertondias.com", '_blank').focus()} 
                    label="ewertondias.com"
                />
            </div>
        )
    }
}