import React from "react";
import "./Screens.css";
import { Routes, Route } from "react-router-dom";
import ScreenA from "../views/ScreenA";
import ScreenB from "../views/ScreenB";
import ScreenC from "../views/ScreenC";
import ScreenD from "../views/ScreenD";
import ScreenE from "../views/ScreenE";

const Screens = props => 
{
    return (
        <main className="content">
            <Routes>
                <Route path="/" element={<ScreenA token={props.token}/>} />
                <Route path="/users" element={<ScreenB token={props.token}/>} />
                <Route path="/materials" element={<ScreenC token={props.token}/>} />
                <Route path="/notifications" element={<ScreenD token={props.token}/>} />
                <Route path="/system" element={<ScreenE token={props.token} onLogout={props.onLogout}/>} />
            </Routes>
        </main>
    )
}

export default Screens;