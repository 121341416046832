import React, { Component } from "react";
import "../components/Screens.css";

import { BsEye } from "react-icons/bs";

import Box from "../components/Box";
import BoxScreen from "../components/BoxScreen";
import Button from "../components/Button";
import Input from "../components/Input";
import Message from "../components/Message";
import MessageConfirmation from "../components/MessageConfirmation";

import API from "../api/index";

const ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT = {
    messageTypeConfirmation: "information",
    messageShowConfirmation: false,
    messageConfirmation: "",
    statusConfirmation: false,
    nameFunctionYesModalConfirmation: "",
    objectConfirmation: {},
}

const initialState = {

    token: "",
    avatarDefault: "",
    saving: false,
    allUsers: [],
    allUsersNotFiltered: [],
    amountAllUsers: 0,
    amountInactiveUsers: 0,
    amountActiveUsers: 0,

    idDetailsUser: 0,
    searchNameUserInUsers: "",

    // start message modal
    message: "",
    messageType: "information",
    messageShow: false,
    // ends message modal

    ...ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT
}

export default class ScreenB extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let token = await this.getToken();
        if (token) { await this.startScreen(); };
    }

    componentDidUpdate = async (prevProps) =>
    {
        let token = await this.getToken();

        // console.log("SCREEN B this.props: ", this.props);

        if (token && this.state.token === "")
        {
            await this.setState({token});
            await this.startScreen();
        }
    }

    getToken = async () =>
    {
        return this.props.token;
    }

    startScreen = async () =>
    {
        await this.getInfoSystem();
        await this.getAllUsers();
    }

    message = async (type, message) =>
    {
        await this.setState(
            {
                messageShow: true,
                messageType: type,
                message: message
            }
        );
    }

    getAllUsers = async () =>
    {
        let token = await this.getToken();
        let api = new API();
        let allUsers = [];
        let allUsersNotFiltered = [];
        let amountAllUsers = 0;
        let amountInactiveUsers = 0;
        let amountActiveUsers = 0;

        // INFORMATION: getting the all users...
        let response = await api.user().getAll(token);
        
        if (response.code === 200)
        {
            let data = response.data;

            Object.keys(data).forEach(k => {
                let user = data[k];
                allUsers.push({...user, id: k});
                if (user.status === "ACTIVE")
                { amountActiveUsers++; }
                else
                { amountInactiveUsers++; }
            });
        }
        else
        {
            await this.message("error", response.message);
            return;
        }

        // INFORMATION: getting the all simulated by user...
        let responseSimulated = await api.simulated().getAll(token);

        if (responseSimulated.code === 200)
        {
            let data = responseSimulated.data;
            let allSimulatedByUser = [];

            // separating...
            Object.keys(data).forEach(k => {
                let allSimuladatedByUserObject = data[k];
                let idUser = k;

                Object.keys(allSimuladatedByUserObject).forEach(ks => {
                    let simulated = allSimuladatedByUserObject[ks];
                    allSimulatedByUser.push({simulated, idUser});
                });

            });

            //by each user...
            allUsers = allUsers.map(u => {
                let finishedSimulated = 0;
                let notFinishedSimulated = 0;

                //all simulated
                for (let i = 0; i < allSimulatedByUser.length; i++) 
                {
                    let simulated = allSimulatedByUser[i];
                    
                    if (simulated.idUser === u.id)
                    {
                        let finished = simulated.simulated.finished;

                        if (finished)
                        { finishedSimulated++; }
                        else
                        { notFinishedSimulated++; }
                    }
                }

                u.allSimuladated = (finishedSimulated + notFinishedSimulated);
                u.finishedSimulated = finishedSimulated;
                u.notFinishedSimulated = notFinishedSimulated;

                return u;
            });
        }

        allUsersNotFiltered = allUsers;
        amountAllUsers = (amountActiveUsers + amountInactiveUsers);

        await this.setState({allUsers, allUsersNotFiltered, amountAllUsers, amountActiveUsers, amountInactiveUsers});
    }

    getInfoSystem = async () =>
    {
        let token = await this.getToken();
        let api = new API();
        let avatarDefault = "";

        let response = await api.general().system(token);

        if (response.code !== 200)
        {
            this.message("error", response.message);
        }
        else
        {
            avatarDefault = response.data.avatarDefault;
        }
        
        await this.setState({avatarDefault});
    }

    onToggleStatus = async () =>
    {
        if (!this.state.saving)
        {
            let objectConfirmation = await this.state.objectConfirmation;
            let statusConfirmation = await this.state.statusConfirmation;

            if (statusConfirmation)
            {
                await this.setState({saving: true});

                let api = new API();
                let token = await this.getToken();
                let {id, status} = objectConfirmation;
                status = (status === "ACTIVE") ? "INACTIVE" : "ACTIVE";
                let result = await api.user().update(id, token, {status});
                
                if (result.code === 200)
                {
                    this.message("success", `Usuário ${objectConfirmation.name} atualizado com sucesso!`);
                    await this.getAllUsers();
                }
                else
                {
                    this.message("error", result.message);
                }
                
                await this.setState({...ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT, saving: false});
            }
            else
            {
                let finalStatusPT = (objectConfirmation.status === "ACTIVE") ? "Inativo" : "Ativo";

                await this.setState({
                    messageTypeConfirmation: "warning",
                    messageShowConfirmation: true,
                    messageConfirmation: `Deseja realmente alterar o status do usuário para ${finalStatusPT}?`,
                    nameFunctionYesModalConfirmation: "onToggleStatus"
                });
            }
        }
        else
        {
            this.message("error", "Opsssss, aguarde o processo atual terminar!");
        }
    }

    toggleDetailsUser = async (idUser) => 
    {
        let idDetailsUser = await this.state.idDetailsUser;

        if (idDetailsUser === idUser)
        {
            await this.setState({idDetailsUser: ""});
        }
        else
        {
            await this.setState({idDetailsUser: idUser});
        } 
    }

    filterUserByNameInUsers = async (name) =>
    {
        name = name.toLowerCase();
        let users = await this.state.allUsersNotFiltered;
        let allUsers = [];

        if (name.toString().trim() !== "")
        {
            users.forEach(u => {
                let n = u.name.toString().toLowerCase();
                if (n.indexOf(name) >= 0)
                {
                    allUsers.push(u);
                }
            });
        }
        else
        {
            allUsers = users;
        }

        await this.setState({searchNameUserInUsers: name, allUsers});
    }

    onClickYesModalConfirmation = async () =>
    {
        let fn = await this.state.nameFunctionYesModalConfirmation;
        let obj = await this.state.objectConfirmation;

        await this.setState({...ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT});
        await this.setState({statusConfirmation: true, objectConfirmation: obj}); //@OVERWRITE
        
        switch (fn)
        {
            case "onToggleStatus":
            this.onToggleStatus();
            break;         

            default:
                break;
        }
    }

    onClickNoModalConfirmation = async () =>
    {
        await this.setState({...ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT});
    }

    openImage = (urlImage) =>
    {
        window.open(urlImage);
    }

    render ()
    {
        return (
            <div className="screens">
                <Message
                    messagetype={this.state.messageType}
                    message={this.state.message}
                    onClose={() => this.setState({messageShow: false})}
                    show={this.state.messageShow}
                />
                <MessageConfirmation
                    show={this.state.messageShowConfirmation}
                    message={this.state.messageConfirmation}
                    onClose={() => this.onClickNoModalConfirmation()}
                    onClickNo={() => this.onClickNoModalConfirmation()}
                    onClickYes={() => this.onClickYesModalConfirmation()}
                    messageType={this.state.messageTypeConfirmation}
                />       
                <div className="d-block d-md-none text-center bg-warning p-2 rounded">
                    <span className="text-light">Aviso: a largura mínima para visualizar esse painel é de 768px (tablet)! Se você estiver pelo celular, tente deitá-lo para visualizar.</span>
                </div>               
                <div className="d-none d-md-block">
                    <BoxScreen title="Usuários">
                        <Box subtitle="Todos Usuários">
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <span>Total de usuários: {this.state.amountAllUsers}.</span><br />
                                    <span>Total de usuários ativos: {this.state.amountActiveUsers}.</span><br />
                                    <span>Total de usuários inativos: {this.state.amountInactiveUsers}.</span><br />
                                </div>
                            </div>
                            <div className="row no-gutters line filters">
                                <div className="col-12 column filters">
                                    <Input
                                        placeholder="pequise por um nome de usuário"
                                        value={this.state.searchNameUserInUsers}
                                        onChange={(e) => this.filterUserByNameInUsers(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="table">
                                <div className="row no-gutters w-100 line head">
                                    <div className="col-2 column">
                                        <span>Imagem</span>
                                    </div>
                                    <div className="col-2 column">
                                        <span>Nome</span>
                                    </div>
                                    <div className="col-2 column">
                                        <span>E-mail</span>
                                    </div>
                                    <div className="col-2 column">
                                        <span>Tipo</span>
                                    </div>
                                    <div className="col-2 column">
                                        <span>Status</span>
                                    </div>
                                    <div className="col-2 column">
                                    </div>
                                </div>
                                {
                                    this.state.allUsers.map((u, i) => {
                                        let avatar = (u?.avatar) ? u.avatar : this.state.avatarDefault;

                                        return (
                                            <div key={`${u.id}`}>
                                                <div className="row no-gutters w-100 line body">
                                                    <div className="col-2 column">
                                                        <div className="areaAvatar">
                                                            <img 
                                                                src={avatar} 
                                                                className="avatar" 
                                                                alt="imagem do avatar"
                                                                onClick={() => this.openImage(avatar)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-2 column">
                                                        <span>{u.name}</span>
                                                    </div>
                                                    <div className="col-2 column">
                                                        <div className="spanColumn">
                                                            <div className="spanColumnNoHover">
                                                                {u.mail} 
                                                            </div>
                                                            <div className="spanColumnHover">
                                                                {u.mail} 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 column">
                                                        <span>{u.is_adm ? "Administrador" : "Aluno"}</span>
                                                    </div>
                                                    <div className="col-2 column">
                                                        <Button
                                                            name={`${(u.status === "ACTIVE") ? "Ativo" : "Inativo"}`}
                                                            style={{padding: "5px", margin: "10px"}}
                                                            onClick={async () => {
                                                                await this.setState({objectConfirmation: u});
                                                                await this.onToggleStatus();
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2 column">
                                                        <button 
                                                            className="btnOperation" 
                                                            onClick={() => this.toggleDetailsUser(u.id)}
                                                        >
                                                            <BsEye className="iconOperation" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row no-gutters w-100 line body detailsUser" 
                                                    style={{display: (this.state.idDetailsUser === u.id) ? "inline-grid" : "none"}}
                                                >
                                                    <div className="col-12 columnDetailsUser">
                                                        <span>nome: {u.name}</span>
                                                        <span>versão: {u.version}</span>
                                                        <span>total de simulados montados: {u.allSimuladated}</span>
                                                        <span>total de simulados respondidos: {u.finishedSimulated}</span>
                                                        <span>total de simulados não-respondidos: {u.notFinishedSimulated}</span>
                                                        <span>token expo: ${u.expo}</span>    
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Box>
                    </BoxScreen>
                </div>
            </div>
        )
    }
}