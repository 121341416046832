import "../components/Screens.css";
import React, { Component } from "react"

import BoxScreen from "../components/BoxScreen";
import Input from "../components/Input";
import Box from "../components/Box";
import Message from "../components/Message";

import API from "../api";
import Button from "../components/Button";

const INPUTS_DEFAULT = {
    // start form fiels
    inputAbout: "", //default is empty string
    inputPolicy: "", //default is empty string
    inputSuportMail: "", //default is empty string
    inputVersion: "", //default is empty string

    inputAvatarDefault: null, //default is null
    inputAvatarDefaultURL: null, //default is null
    // ends form fields
}

const initialState = {

    token: "",
    saving: false,

    ...INPUTS_DEFAULT,
    inputImageKey: 0,

    // start message modal
    message: "",
    messageType: "information",
    messageShow: false,
    // ends message modal
}
export default class ScreenA extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    { 
        let token = await this.getToken();
        if (token) { await this.startScreen(); };
    }

    componentDidUpdate = async (prevProps) =>
    {
        let token = await this.getToken();

        // console.log("SCREEN E this.props: ", this.props);

        if (token && this.state.token === "")
        {
            await this.setState({token});
            await this.startScreen();
        }
    }

    startScreen = async () =>
    {
        await this.getAllInformation();
    }

    getToken = async () =>
    {
        return this.props.token;
    }

    getAllInformation = async () =>
    {
        let token = await this.getToken();
        let api = new API();
        let inputAbout = "";
        let inputPolicy = "";
        let inputSuportMail = "";
        let inputVersion = "";
        let inputAvatarDefaultURL = "";

        let response = await api.general().system(token);
        
        if (response.code === 200)
        {
            let data = response.data;

            inputAbout = data.about;
            inputPolicy = data.policy;
            inputSuportMail = data.suportMail;
            inputVersion = data.version
            inputAvatarDefaultURL = data.avatarDefault;
        }
        else
        {
            await this.message("error", response.message);
        }

        await this.setState({
            inputAbout,
            inputPolicy,
            inputSuportMail,
            inputVersion,
            inputAvatarDefaultURL
        });
    }

    changeImage = async (event) => 
    {
        await this.setState({
            inputAvatarDefaultURL: null,
            inputAvatarDefault: null
        });

        if (event.target.files.length > 0)
        {
            let file = event.target.files[0]
            let imageURL = URL.createObjectURL(event.target.files[0]);
            await this.setState({
                inputAvatarDefaultURL: imageURL,
                inputAvatarDefault: file
            });
        }
    }

    message = async (type, message) =>
    {
        await this.setState(
            {
                messageShow: true,
                messageType: type,
                message: message
            }
        );
    }

    setAllInputsAsDefault = async () =>
    {
        await this.setState({
            ...INPUTS_DEFAULT,
            inputImageKey: this.getRandomKey()
        });
    }

    getRandomKey = () =>
    {
        return new Date().getTime();
    }

    save = async () =>
    {
        let inputAbout = await this.state.inputAbout;
        let inputPolicy = await this.state.inputPolicy;
        let inputSuportMail = await this.state.inputSuportMail;
        let inputVersion = await this.state.inputVersion;
        let inputAvatarDefault = await this.state.inputAvatarDefault;
        let saving = await this.state.saving;
        let inputsValid = true;

        if 
        (
            inputAbout.toString().trim() === "" ||
            inputPolicy.toString().trim() === "" ||
            inputSuportMail.toString().trim() === "" ||
            inputVersion.toString().trim() === ""
        ) 
        { inputsValid = false; }

        if (!inputsValid)
        {
            this.message("error", "Opsssss, preencha os campos corretamente.");
            return;
        }

        if (!saving)
        {
            let system = {};
            let api = new API();

            if (inputAvatarDefault !== null)
            {
                this.setState({saving: true});
                this.message("information", "Aguarde; estamos fazendo o upload da imagem...");
                
                let folder = "images";
                let rdn = this.getRandomKey();
                let fileName = `${rdn}.jpg`;
                let resultUpload = await api.general().uploadFile(inputAvatarDefault, fileName, folder);

                if (resultUpload.code !== 200)
                {
                    await this.setState({saving: false});
                    await this.setAllInputsAsDefault();
                    await this.message("error", resultUpload.message);
                    return;
                }

                system.avatarDefault = resultUpload.url_image;
            }

            system.about = inputAbout;
            system.policy = inputPolicy;
            system.suportMail = inputSuportMail;
            system.version = inputVersion;

            let token = await this.getToken();
            let resultUpdate = await api.general().updateSystem(system, token);

            if (resultUpdate.code !== 200)
            {
                this.message("error", resultUpdate.message);
                return;
            }

            await this.setState({saving: false});
            await this.setAllInputsAsDefault();
            await this.getAllInformation();
            await this.message("success", "Informações atualizadas com sucesso!");
        }
        else
        {
            this.message("error", "Opsssss, aguarde o processo atual terminar!");
        }
    }

    openImage = (urlImage) =>
    {
        window.open(urlImage);
    }

    render ()
    {
        return (
            <div className="screens">
                <Message
                    messagetype={this.state.messageType}
                    message={this.state.message}
                    onClose={() => this.setState({messageShow: false})}
                    show={this.state.messageShow}
                />
                <div className="d-block d-md-none text-center bg-warning p-2 rounded">
                    <span className="text-light">Aviso: a largura mínima para visualizar esse painel é de 768px (tablet)! Se você estiver pelo celular, tente deitá-lo para visualizar.</span>
                </div>
                <div className="d-none d-md-block">
                    <BoxScreen title="Sistema">
                        <Box subtitle={`Atualizar`}>
                            <div className="row no-gutters line">
                                <div className="col-6 column pr-1">
                                    <Input 
                                        label="Sobre"
                                        value={this.state.inputAbout}
                                        placeholder="sobre o aplicativo"
                                        onChange={(e) => this.setState({inputAbout: e.target.value})}
                                    />
                                </div>
                                <div className="col-6 column pl-1">
                                    <Input 
                                        label="Política de Privacidade"
                                        value={this.state.inputPolicy}
                                        placeholder="conteúdo da política de privacidade"
                                        onChange={(e) => this.setState({inputPolicy: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-6 column">
                                    <Input
                                        label="E-mail de Suporte"
                                        value={this.state.inputSuportMail}
                                        placeholder="email@dominio.com.br"
                                        onChange={(e) => this.setState({inputSuportMail: e.target.value})}
                                    />
                                </div>
                                <div className="col-6 column">
                                    <Input 
                                        label="Versão do Sistema"
                                        value={this.state.inputVersion}
                                        placeholder="versão do sistema"
                                        onChange={(e) => this.setState({inputVersion: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <Input
                                        label="Avatar Padrão"
                                        type="file"
                                        onChange={(e) => this.changeImage(e)}
                                        accept=".jpeg, .jpg, .png"
                                        key={this.state.inputImageKey}
                                    />
                                </div>
                            </div>
                            {
                                this.state.inputAvatarDefaultURL &&
                                <div className="row no-gutters line">
                                    <div className="col-12 column">
                                        <div className="imagePreview">
                                            <img 
                                                src={this.state.inputAvatarDefaultURL} 
                                                alt="Imagem da Questão"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row no-gutters line">
                                <div className="col-12 column pr-1 d-flex justify-content-center">
                                    <Button
                                        name={`atualizar`} 
                                        style={{"maxWidth": "200px"}}
                                        onClick={this.save} 
                                    />
                                </div>
                            </div>
                        </Box>
                        <Box subtitle="">
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <Button 
                                        name="sair"
                                        onClick={() => this.props.onLogout()}
                                        style={{maxWidth: "200px"}}
                                    />
                                </div>
                            </div>
                        </Box>
                    </BoxScreen>
                </div>
            </div>
        )
    }
}