import React from "react";
import "./BoxScreen.css";

export default function BoxScreen (props) {
    return (
        <div className="boxScreen">
            <h2 className="title">{props.title}</h2>
            {props.children}
        </div>
    )
}