import React from "react";
import "./Check.css";

export default function Check (props)  
{
    return (
        <button   
            className={`check ${(props.active) ? 'active': ''}`}
            onClick={() => props.onClick()}
            style={{ 
                ...props?.style
            }} 
        >
        </button>
    )
}