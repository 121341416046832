import React, { Component } from "react";
import "../components/Screens.css";

import Box from "../components/Box";
import BoxScreen from "../components/BoxScreen";
import Button from "../components/Button";
import Check from "../components/Check";
import Input from "../components/Input";
import Message from "../components/Message";

import API from "../api/index";

// const devices = [
//     {
//         checked: true,
//         name: "Maria Gabriela Santos",
//         expo: "e5tg-lXhgRM:APA91bEyTuEnhKVCl66XqVkmdfsdfsdsfsdfbvvbgt4kVnxFoGTvCRgu46ZBtOdZ",
//         is_adm: false
//     },
//     {
//         checked: false,
//         name: "João Gabriel Lima",
//         expo: "e5tg-lXhgRM:APA91bEyTuasdasdEnhKVCl66XqVkm4kVnxFoGTvCRgu46ZBtOdZ",
//         is_adm: true
//     },
//     {
//         checked: false,
//         name: "Gustavo Jesus",
//         expo: "e5tg-lXhgRM:APA91basdafasdfEyTuEnhKVCl66XqVkm4kVnxFoGTvCRgu46ZBtOdZ",
//         is_adm: false
//     },
// ]

const INPUTS_DEFAULT = {
    token: "",
    titleNotification: "",
    messageNotification: "",
    saving: false,
}

const initialState = {
    ...INPUTS_DEFAULT,

    users: [],
    usersNoFiltered: [],
    searchNameUserInUsers: "",
    allChecked: false,

    // start message modal
    message: "",
    messageType: "information",
    messageShow: false,
    // ends message modal
}
export default class ScreenD extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        let token = await this.getToken();
        if (token) { await this.startScreen(); };
    }

    componentDidUpdate = async (props) =>
    {
        let token = await this.getToken();

        // console.log("SCREEN D this.props: ", this.props);

        if (token && this.state.token === "")
        {
            await this.setState({token});
            await this.startScreen();
        }
    }
    
    startScreen = async () =>
    {
        await this.getAllUsers();
    }

    getToken = async () =>
    {
        return this.props.token;
    }

    getAllUsers = async () =>
    {
        let users = [];
        let usersNoFiltered = [];

        let api = new API();
        let token = await this.getToken();
        let result = await api.user().getAll(token);

        if (result.code === 200)
        {
            let data = result.data;

            Object.keys(data).forEach((key, i) => {
                let {name, expo, is_adm} = data[key];

                let user = {
                    id: key,
                    name,
                    expo,
                    is_adm,
                    checked: false,
                }

                users.push(user);
            });
        }
        
        usersNoFiltered = users;

        await this.setState({users, usersNoFiltered});
    }

    getCurrentDateUSA = () =>
    {
        let dateCurrent = new Date();
        let y, m, d = 0;
        y = dateCurrent.getFullYear();
        d = dateCurrent.getDate();
        d = ("0" + d).slice(-2);
        m = dateCurrent.getMonth();
        m = ("0" + (m + 1)).slice(-2);
    
        return `${y}-${m}-${d}`;
    }

    sendNotification = async () =>
    {
        let title = await this.state.titleNotification;
        let message = await this.state.messageNotification;
        let saving = await this.state.saving;

        if (saving) { return await this.message("information", "Opsssss, aguarde o processo atual terminar."); }

        await this.setState({saving: true});
        
        let today = this.getCurrentDateUSA();
        let users = await this.state.users;
        let devices = [];
        let targets = [];

        users.forEach(u => {
            let expo = u.expo;

            if (u.checked && expo !== "" && expo !== undefined)
            {
                devices.push(
                    {
                        "to": expo,
                        "sound": "default",
                        "body": message,
                        "title": title,
                        "trigger": { seconds: 2 }
                    }
                );

                targets.push(
                    {
                        "id": u.id,
                        "date": today,
                        "title": title,
                        "description": message,
                        "read": false
                    }
                );
            }
        });

        if (title.toString().trim() === "" || message.toString().trim() === "")
        {
            await this.message("error", "Opsssss, o título e a mensagem da notificação devem ser preenchidos!");
            return;
        }

        if (devices.length <= 0)
        {
            await this.message("error", "Opsssss, escolha pelo menos um usuário para enviar uma notificação!");
            return;
        }

        let token = await this.getToken();
        let api = new API();
        let response = await api.general().sendNotification(devices, targets, token);

        if (response.code !== 200)
        {
            this.message("error", response.message);
            return;
        }

        await this.message("success", "Obaaaaa, conseguimos enviar a(s) notificação(ões)!");
        await this.setAllInputsAsDefault();
        await this.getAllUsers();
    }

    toggleCheckDevice = async (expo) =>
    {
        let users = await this.state.users;
        let allChecked = true;

        users.map(u => {
            if (u.expo === expo) { u.checked = !u.checked; }
            return u;
        });

        users.forEach(u => {
            if (!u.checked) { allChecked = false; }
        });

        await this.setState({users, allChecked});
    }

    toggleCheckAllDevices = async () =>
    {
        let allChecked = await this.state.allChecked;
        let users = await this.state.users;

        allChecked = !allChecked;

        users = users.map(u => {
            u.checked = allChecked;
            return u;
        });

        await this.setState({allChecked, users});
    }

    message = async (type, message) =>
    {
        await this.setState(
            {
                messageShow: true,
                messageType: type,
                message: message
            }
        );
    }

    setAllInputsAsDefault = async () =>
    {
        await this.setState({...INPUTS_DEFAULT});
    }

    filterUserByNameInUsers = async (name) =>
    {
        name = name.toLowerCase();
        let usersNoFiltered = await this.state.usersNoFiltered;
        let users = [];

        if (name.toString().trim() !== "")
        {
            usersNoFiltered.forEach(u => {
                let n = u.name.toString().toLowerCase();
                if (n.indexOf(name) >= 0)
                {
                    users.push(u);
                }
            });
        }
        else
        {
            users = usersNoFiltered;
        }

        await this.setState({searchNameUserInUsers: name, users});
    }

    render ()
    {
        return (
            <div className="screens">
                <Message
                    messagetype={this.state.messageType}
                    message={this.state.message}
                    onClose={() => this.setState({messageShow: false})}
                    show={this.state.messageShow}
                />
                <div className="d-block d-md-none text-center bg-warning p-2 rounded">
                    <span className="text-light">Aviso: a largura mínima para visualizar esse painel é de 768px (tablet)! Se você estiver pelo celular, tente deitá-lo para visualizar.</span>
                </div>
                <div className="d-none d-md-block">
                    <BoxScreen title="Notificações">
                        <Box subtitle="Enviar Notificação">
                            <form onSubmit={(e) => e.preventDefault()} >
                                <div className="row no-gutters line">
                                    <div className="col-6 column">
                                        <Input 
                                            label="Título"
                                            placeholder="[Novidade], [Aviso]"
                                            type="text"
                                            value={this.state.titleNotification}
                                            onChange={(e) => this.setState({titleNotification: e.target.value})}
                                        />
                                    </div>
                                    <div className="col-6 column">
                                        <Input 
                                            label="Mensagem"
                                            placeholder="Olá, tem material novo no aplicativo."
                                            type="text"
                                            value={this.state.messageNotification}
                                            onChange={(e) => this.setState({messageNotification: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="row no-gutters line">
                                    <div className="col-12 column">
                                    <Input
                                        label="Todos os Dispositivos"
                                        placeholder="pequise por um nome de usuário"
                                        value={this.state.searchNameUserInUsers}
                                        onChange={(e) => this.filterUserByNameInUsers(e.target.value)}
                                    />
                                    </div>
                                </div>
                                <div className="row no-gutters line">
                                    <div className="col-12 column">
                                        <div className="table">
                                            <div className="row no-gutters line head">
                                                <div className="col-1 column">
                                                    <Check 
                                                        active={this.state.allChecked} 
                                                        onClick={this.toggleCheckAllDevices}
                                                    />
                                                </div>
                                                <div className="col-3 column">
                                                    <span>Nome</span>
                                                </div>
                                                <div className="col-6 column">
                                                    <span>Token</span>
                                                </div>
                                                <div className="col-2 column">
                                                    <span>Tipo</span>
                                                </div>
                                            </div>
                                            {
                                                this.state.users.map((user, i) => {
                                                    return (
                                                        <div key={i} className="row no-gutters line body">
                                                            <div className="col-1 column">
                                                                <Check 
                                                                    active={user.checked} 
                                                                    onClick={() => this.toggleCheckDevice(user.expo)}
                                                                />
                                                            </div>
                                                            <div className="col-3 column">
                                                                <span>{user.name}</span>
                                                            </div>
                                                            <div className="col-6 column">
                                                                <div className="spanColumn">
                                                                    <div className="spanColumnNoHover">
                                                                        {(user.expo === "" || user.expo === undefined) ? "[não é possível enviar para; sem token]" : user.expo}
                                                                    </div>
                                                                    <div className="spanColumnHover">
                                                                        {(user.expo === "" || user.expo === undefined) ? "[não é possível enviar para; sem token]" : user.expo}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 column">
                                                                <span>{user.is_adm ? "Administrador" : "Aluno"}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters line">
                                    <div className="col-12 d-flex justify-content-center">
                                        <Button 
                                            name="enviar"
                                            style={{maxWidth: "200px"}}
                                            onClick={this.sendNotification}
                                        />
                                    </div>
                                </div>
                            </form>                    
                        </Box>
                    </BoxScreen>
                </div>
            </div>
        )
    }
}