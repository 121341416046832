import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Menu.css";

const Menu = props =>
{
    const [isOpened, setOpened] = useState(true);
    const [routes, setRouteActive] = useState("users");
    const location = useLocation();

    useEffect(() => {
        let route = location.pathname;
        route = route.replace("/", "");
        setRouteActive(route);
        
    },[routes, setRouteActive, location])

    return (
        <div className={`menu ${isOpened ? "active" : "inactive"}`}>
            <button
                className="buttonToggle"
                onClick={() => setOpened(isOpened ? false : true)}
            >
                {isOpened 
                    ? <i className="fas fa-arrow-circle-left btnToggleClose"></i>
                    :<i className="fas fa-arrow-circle-right btnToggleOpen"></i>                
                }
            </button>
            <nav>
                <ul>
                    <li>
                        <Link
                            to="/" className={(routes === "" ? "active" : null)} 
                            onClick={() => {}}
                        >
                            <i className="fas fa-question iconMenu"></i>
                            <span>Questões</span>
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to="/users" className={(routes === "users" ? "active" : null)}
                            onClick={() => {}}
                        >
                            <i className="fas fa-users iconMenu"></i>
                            <span>Usuários</span>
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to="/materials" className={(routes === "materials" ? "active" : null)}
                            onClick={() => {}}
                        >
                            <i className="fas fa-file iconMenu"></i>
                            <span>Materiais</span>
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to="/notifications" className={(routes === "notifications" ? "active" : null)}
                            onClick={() => {}}
                        >
                            <i className="fas fa-bell iconMenu"></i>
                            <span>Notificações</span>
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to="/system" className={(routes === "system" ? "active" : null)}
                            onClick={() => {}}
                        >
                            <i className="fas fa-cog iconMenu"></i>
                            <span>Sistema</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Menu;