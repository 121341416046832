import "../components/Screens.css";
import React, { Component } from "react"
import { MdModeEdit } from "react-icons/md";
import { FiTrash2 } from "react-icons/fi";
import { FaFilePdf } from "react-icons/fa";

import Select from "../components/Select";
import BoxScreen from "../components/BoxScreen";
import Input from "../components/Input";
import Box from "../components/Box";
import Message from "../components/Message";
import MessageConfirmation from "../components/MessageConfirmation";
import Button from "../components/Button";

import API from "../api";

const INPUTS_DEFAULT = {
    // start form fiels
    inputIdMaterialOnUpdating: "", //default is empty string
    inputName: "", //default is empty string
    inputSize: "", //default is empty string
    inputTypeFile: 0,
    inputFile: null, //default is null
    inputFileURL: null, //default is null
    // ends form fields
}

const ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT = {
    messageTypeConfirmation: "information",
    messageShowConfirmation: false,
    messageConfirmation: "",
    statusConfirmation: false,
    nameFunctionYesModalConfirmation: "",
    objectConfirmation: {},
}

const initialState = {

    name: "",
    token: "",
    allMaterials: [],
    allMaterialsNotFiltered: [],
    amountMaterialsInDataBase: 0,
    amountMaterialsFounded: 0,
    saving: false,
    modeUpdate: false,

    ...INPUTS_DEFAULT,
    inputFileKey: 0,
    allTypesFile: [
        {
            label: "pdf",
            value: 0,
        }
    ],

    // start message modal
    message: "",
    messageType: "information",
    messageShow: false,
    // ends message modal

    ...ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT
}
export default class ScreenC extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    { 
        let token = await this.getToken();
        if (token) { await this.startScreen(); };
    }

    componentDidUpdate = async (prevProps) =>
    {
        let token = await this.getToken();

        // console.log("SCREEN C this.props: ", this.props);

        if (token && this.state.token === "")
        {
            await this.setState({token});
            await this.startScreen();
        }
    }

    startScreen = async () =>
    {
        await this.getAllMaterials();
    }

    getToken = async () =>
    {
        return this.props.token;
    }

    getAllMaterials = async () =>
    {
        let token = await this.getToken();
        let api = new API();
        let allMaterials = [];
        let allMaterialsNotFiltered = [];
        let response = await api.material().getAll(token);

        if (response.code === 200)
        {
            let data = response.data;

            if (!data) { return; }
            Object.keys(data).forEach(k => {
                let question = data[k];
                allMaterials.push({...question, id: k});
            });
        }
        else
        {
            await this.message("error", response.message);
        }

        allMaterialsNotFiltered = allMaterials;
        await this.setState({allMaterials, allMaterialsNotFiltered, amountMaterialsInDataBase: allMaterials.length});
        await this.applyFilters(allMaterialsNotFiltered);
    }

    applyFilters = async (allMaterialsNotFiltered) =>
    {
        let name = await this.state.name;
        name = name.toLowerCase();
        let allMaterials = [];

        if (name.toString().trim() !== "")
        {
            allMaterialsNotFiltered.forEach(u => {
                let n = u.name.toString().toLowerCase();
                if (n.indexOf(name) >= 0)
                {
                    allMaterials.push(u);
                }
            });
        }
        else
        {
            allMaterials = allMaterialsNotFiltered;
        }

        await this.setState({allMaterials, amountMaterialsFounded: allMaterials.length});
    }

    changeFile = async (event) => 
    {
        await this.setState({
            inputFileURL: null,
            inputFile: null
        });

        if (event.target.files.length > 0)
        {
            let file = event.target.files[0]
            let fileURL = URL.createObjectURL(event.target.files[0]);
            await this.setState({
                inputFileURL: fileURL,
                inputFile: file
            });
        }
    }

    message = async (type, message) =>
    {
        await this.setState(
            {
                messageShow: true,
                messageType: type,
                message: message
            }
        );
    }

    setAllInputsAsDefault = async () =>
    {
        await this.setState(
            {
                ...INPUTS_DEFAULT,
                inputFileKey: this.getRandomKey()
            }
        );
    }

    getRandomKey = () =>
    {
        return new Date().getTime();
    }

    save = async () =>
    {
        let allTypesFile = await this.state.allTypesFile;
        let inputName = await this.state.inputName;
        let inputSize = await this.state.inputSize;
        let inputTypeFile = await this.state.inputTypeFile;
        let inputFile = await this.state.inputFile;
        let modeUpdate = await this.state.modeUpdate;
        let inputIdMaterialOnUpdating = await this.state.inputIdMaterialOnUpdating;
        let inputsValid = true;
        
        // @OVERWRITE allTypesFile from number to string type
        inputTypeFile = allTypesFile.filter(t => t.value === inputTypeFile)[0].label;

        if 
        (
            inputName.toString().trim() === "" ||
            inputSize.toString().trim() === "" ||
            inputTypeFile.toString().trim() === ""
        ) 
        { inputsValid = false; }

        if (!inputsValid)
        {
            this.message("error", "Opsssss, preencha os campos corretamente.");
            return;
        }

        if (!this.state.saving)
        {
            let material = {};
            let api = new API();

            if (inputFile !== null) //INFORMATION: optional field.
            {
                this.setState({saving: true});
                this.message("information", "Aguarde; estamos fazendo o upload do arquivo...");
                
                let folder = "materials";
                let fileName = `${inputName}.pdf`;
                let resultUpload = await api.general().uploadFile(inputFile, fileName, folder);

                if (resultUpload.code !== 200)
                {
                    await this.setState({saving: false, modeUpdate: false});
                    await this.setAllInputsAsDefault();
                    await this.message("error", resultUpload.message);
                    return;
                }

                material.url = resultUpload.url_image;
            }

            if (inputFile === null && !modeUpdate)
            {
                await this.setState({saving: false, modeUpdate: false});
                await this.setAllInputsAsDefault();
                await this.message("error", "Opsssss, é obrigatório fazer o upload do arquivo para poder cadastrar o material!");
                return;
            }

            material.name = inputName;
            material.size = inputSize;
            material.type = inputTypeFile;

            let token = await this.getToken();
            let resultMaterial = null;

            if (!modeUpdate)
            {
                resultMaterial = await api.material().create(material, token);
            }
            else
            {
                resultMaterial = await api.material().update(inputIdMaterialOnUpdating, material, token);
            }

            if (resultMaterial.code !== 200)
            {
                this.message("error", resultMaterial.message);
                return;
            }

            await this.getAllMaterials();
            await this.setState({saving: false, modeUpdate: false});
            await this.setAllInputsAsDefault();
            await this.message("success", "Material cadastrado com sucesso!");
        }
        else
        {
            this.message("error", "Opsssss, aguarde o processo atual terminar!");
        }
    }

    onEdit = async (m) =>
    {
        let allTypesFile = await this.state.allTypesFile;
        let inputIdMaterialOnUpdating = m.id;
        let inputName = m.name;
        let inputSize = m.size;
        let inputTypeFile = allTypesFile.filter(t => t.label === m.type)[0].value;

        await this.setState({
            modeUpdate: true,
            inputName,
            inputSize,
            inputTypeFile,
            inputIdMaterialOnUpdating,
        });
    }

    onDelete = async () =>
    {
        if (!this.state.saving)
        {
            let objectConfirmation = await this.state.objectConfirmation;
            let statusConfirmation = await this.state.statusConfirmation;

            if (statusConfirmation)
            {
                await this.setState({saving: true});

                let api = new API();
                let token = await this.getToken();
                let result = await api.material().remove(objectConfirmation.id, token);
                
                if (result.code === 200)
                {
                    this.message("success", `Material "${objectConfirmation.name}" excluído com sucesso!`);
                    await this.getAllMaterials();
                }
                else
                {
                    this.message("error", result.message);
                }
                
                await this.setState({...ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT, saving: false});
            }
            else
            {
                await this.setState({
                    messageTypeConfirmation: "warning",
                    messageShowConfirmation: true,
                    messageConfirmation: `Deseja realmente excluir o material "${objectConfirmation.name}"?`,
                    nameFunctionYesModalConfirmation: "onDelete"
                });
            }
        }
        else
        {
            this.message("error", "Opsssss, aguarde o processo atual terminar!");
        }
    }

    onClickYesModalConfirmation = async () =>
    {
        let fn = await this.state.nameFunctionYesModalConfirmation;
        let obj = await this.state.objectConfirmation;

        await this.setState({...ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT});
        await this.setState({statusConfirmation: true, objectConfirmation: obj}); //@OVERWRITE
        
        switch (fn)
        {
            case "onDelete":
            this.onDelete();
            break;         

            default:
                break;
        }
    }

    onClickNoModalConfirmation = async () =>
    {
        await this.setState({...ATTRIBUTES_MODAL_CONFIRMATION_DEFAULT});
    }

    openFile = (urlFile) =>
    {
        window.open(urlFile);
    }

    render ()
    {
        return (
            <div className="screens">
                <Message
                    messagetype={this.state.messageType}
                    message={this.state.message}
                    onClose={() => this.setState({messageShow: false})}
                    show={this.state.messageShow}
                />
                <MessageConfirmation
                    show={this.state.messageShowConfirmation}
                    message={this.state.messageConfirmation}
                    onClose={() => this.onClickNoModalConfirmation()}
                    onClickNo={() => this.onClickNoModalConfirmation()}
                    onClickYes={() => this.onClickYesModalConfirmation()}
                    messageType={this.state.messageTypeConfirmation}
                />
                <div className="d-block d-md-none text-center bg-warning p-2 rounded">
                    <span className="text-light">Aviso: a largura mínima para visualizar esse painel é de 768px (tablet)! Se você estiver pelo celular, tente deitá-lo para visualizar.</span>
                </div>
                <div className="d-none d-md-block">
                    <BoxScreen title="Materiais">
                        <Box subtitle={`${this.state.modeUpdate ? "Atualizar" : "Cadastrar"} Material`}>
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <Input 
                                        label="Nome"
                                        value={this.state.inputName}
                                        placeholder="nome do material"
                                        onChange={async (e) => {
                                            let name = e.target.value;
                                            await this.setState({inputName: name});
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-9 column">
                                    <Input 
                                        label="Tamanho"
                                        value={this.state.inputSize}
                                        placeholder="tamanho do arquivo (113 KB)"
                                        onChange={async (e) => {
                                            let description = e.target.value;
                                            await this.setState({inputSize: description});
                                        }}
                                    />
                                </div>
                                <div className="col-3 column pl-1">
                                    <Select
                                        label="Tipo"
                                        checked={this.state.inputTypeFile}
                                        options={this.state.allTypesFile} 
                                        onChange={async (value) => {
                                            await this.setState({inputTypeFile: value});
                                        }} 
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <Input
                                        label="Arquivo"
                                        type="file"
                                        onChange={(e) => this.changeFile(e)}
                                        accept=".pdf"
                                        key={this.state.inputFileKey}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters line">
                                <div className="col-12 column pr-1 d-flex justify-content-center">
                                    <Button
                                        name={`${this.state.modeUpdate ? "atualizar" : "cadastrar"}`} 
                                        style={{"maxWidth": "200px"}}
                                        onClick={this.save} 
                                    />
                                </div>
                            </div>
                        </Box>
                        <Box subtitle="Todos os Materiais">
                            <div className="row no-gutters line">
                                <div className="col-12 column">
                                    <span>Total de materiais: {this.state.amountMaterialsInDataBase}.</span><br />
                                    <span>Total de materiais encontrados com o termo de pesquisa: {this.state.amountMaterialsFounded}.</span><br />
                                </div>
                            </div>
                            <div className="row no-gutters line filters">
                                <div className="col-9 column filters pr-1">
                                    <Input 
                                        value={this.state.name}
                                        placeholder="nome do material"
                                        onChange={async (e) => {
                                            let name = e.target.value;
                                            await this.setState({name});
                                            let allMaterialsNotFiltered = await this.state.allMaterialsNotFiltered;
                                            await this.applyFilters(allMaterialsNotFiltered);
                                        }}
                                    />
                                </div>
                                <div className="col-3 column filters pr-1 pl-1">
                                    <Select
                                        checked={this.state.inputTypeFile}
                                        options={this.state.allTypesFile} 
                                        onChange={async (value) => {
                                            await this.setState({inputTypeFile: value});
                                            let allMaterialsNotFiltered = await this.state.allMaterialsNotFiltered;
                                            await this.applyFilters(allMaterialsNotFiltered);
                                        }} 
                                    />
                                </div>
                            </div>
                            <div className="table">
                                <div className="row no-gutters w-100 line head">
                                    <div className="col-2 column">
                                        <span>Arquivo</span>
                                    </div>
                                    <div className="col-3 column">
                                        <span>Nome</span>
                                    </div>
                                    <div className="col-2 column">
                                        <span>Tipo</span>
                                    </div>
                                    <div className="col-2 column">
                                        <span>Tamanho</span>
                                    </div>
                                    <div className="col-3 column">
                                        <span>Ações</span>
                                    </div>
                                </div>
                                {
                                    this.state.allMaterials.map((m, i) => {

                                        return (
                                            <div className="row no-gutters w-100 line body" key={`${i}`}>
                                                <div className="col-2 column">
                                                    <FaFilePdf className="file" size={24} onClick={() => this.openFile(m.url)}/>
                                                </div>
                                                <div className="col-3 column">
                                                    <div className="spanColumn">
                                                        <div className="spanColumnNoHover">
                                                            {m.name}
                                                        </div>
                                                        <div className="spanColumnHover">
                                                            {m.name}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-2 column">
                                                    <div className="spanColumn">
                                                        <span>{m.type}</span>
                                                    </div>
                                                </div>
                                                <div className="col-2 column">
                                                    <span>{m.size}</span>
                                                </div>
                                                <div className="col-3 column">
                                                    <button 
                                                        className="btnOperation" 
                                                        onClick={() => this.onEdit(m)}
                                                    >
                                                        <MdModeEdit className="iconOperation" />
                                                    </button>
                                                    <button 
                                                        className="btnOperation" 
                                                        onClick={async () => {
                                                            await this.setState({objectConfirmation: m});
                                                            await this.onDelete();
                                                        }}
                                                    >
                                                        <FiTrash2 className="iconOperation" />
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Box>
                    </BoxScreen>
                </div>
            </div>
        )
    }
}