import "./Panel.css";
import "./Panel.css";

import React, { Component } from "react";
import { NAME_APPLICATION } from "./constants";
import { BrowserRouter as Router } from "react-router-dom";

import Menu from "./components/Menu";
import Screens from "./components/Screens";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Message from "./components/Message";

import API from "./api";

const initialState = {
    tokenExpiresAtUser: 0,
    token: "",

    // start message modal
    message: "",
    messageType: "information",
    messageShow: false,
    // ends message modal
}

export default class Panel extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let userStorage = localStorage.getItem(`USER_${NAME_APPLICATION}`);
        let u = JSON.parse(userStorage);

        await this.setState({token: u?.token});
        await this.verifyToken();
    }

    verifyToken = async () =>
    {
        setInterval(async () => {

            let userStorage = localStorage.getItem(`USER_${NAME_APPLICATION}`);
            let u = JSON.parse(userStorage);
            let tokenExpiresAtUser = u?.token_expires_at || 0;

            let seconds = new Date().getTime() / 1000;
            let secondsTokenExpiresAt = tokenExpiresAtUser;
            let diffTime = (secondsTokenExpiresAt - seconds);

            if ((seconds > secondsTokenExpiresAt) && secondsTokenExpiresAt !== 0) //INFORMATION: Taking the user to the login screen again, as it was not possible to renew the token, as the browser is closed.
            {
                // expired token!!!
                let message = "Opsssss, não foi possível renovar o token. Faça o login novamente!";
                this.props.onLogout(message);
            }

            if (diffTime < 300 && diffTime > 0) // INFORMATION: Renewing the token, in the last 5 minutes of every hour, when the browser is open.
            {
                await this.refreshToken();
            }
        }, 1000)
    }

    refreshToken = async () =>
    {
        console.log("refreshing token...");

        let userStorage = await localStorage.getItem(`USER_${NAME_APPLICATION}`);
        let user = JSON.parse(userStorage);

        let api = new API();
        
        let resultRefresh = await api.user().refreshToken(user.refreshToken);

        if (resultRefresh.code === 200)
        {
            let seconds = new Date().getTime()/1000;
            let userToken = resultRefresh.data.data.id_token;
            let userRefreshToken = resultRefresh.data.data.refresh_token;
            let userUpdated = {
                token: userToken,
                refreshToken: userRefreshToken,
                token_expires_at: seconds+3600,
            };
            await localStorage.setItem(`USER_${NAME_APPLICATION}`, JSON.stringify(userUpdated));            
            await this.setState({token: userToken});
        }
    }

    message = async (type, message) =>
    {
        await this.setState(
            {
                messageShow: true,
                messageType: type,
                message: message
            }
        );
    }

    render ()
    {
        return (
            <div className="panel">
                <Message
                    messagetype={this.state.messageType}
                    message={this.state.message}
                    onClose={() => this.setState({messageShow: false})}
                    show={this.state.messageShow}
                />
                <Header />
                <div className="content">
                    <Router>
                        <Menu/>
                        <Screens token={this.state.token} onLogout={this.props.onLogout}/>
                    </Router>
                </div>
                <Footer />
            </div>
        )
    }
}