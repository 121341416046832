import "./Input.css";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { useState } from "react";

export default function Input (props)
{
    const [helpHover, setHelpHover] = useState(false);

    return (
        <div className="input">   
            {
                props.label &&
                <div className="label"> 
                    <span className="textLabel">{props.label}</span>
                    {
                        props.help &&
                        <div className="help"  
                            onMouseOver={() => setHelpHover(true)}  
                            onMouseLeave={() => setHelpHover(false)}
                        >
                            <IoIosHelpCircleOutline className="icon" />
                            {helpHover && <span className="message">{props.help}</span>}                        
                        </div>
                    }
                </div>
            }
            <input className="input" {...props} onChange={(e) => props?.onChange(e)} />
        </div>
    )
}