import axios from "axios";
import { auth, storage } from "../firebase/firebase";
import { API_KEY_FIREBASE } from "../constants";

const authBaseURL = "https://www.googleapis.com/identitytoolkit/v3/relyingparty";
const authRefreshBaseURL = "https://securetoken.googleapis.com/v1/token";
const changeBaseURL = "https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode";


// WARNING: Some functions of this API were made based on the Firebase SDK, not the Rest API. The reason is that, to upload via the web, on firebase storage, it is not possible to send a token via http request (url), and to authenticate it would have to be done through the authentication of the application itself, and therefore, the use of the SDK. Furthermore, even if I disable the security rule, the image upload by the functions (based on 64, as the app itself uses), ends up generating a 1x1px white image (probably the web application is not supported).

class API
{
    // Erro's code U
    user ()
    {
        // erro's code U100 to U199
        async function create (user) 
        {
            let status = {
                code: "",
                message: "",
            }

            await axios.post(`${authBaseURL}/signupNewUser?key=${API_KEY_FIREBASE}`, {
                email: user.mail,
                password: user.password,
                returnSecureToken: true
            })
            .then(async (res) => {
                
                if (res.data.localId) {
                    
                    delete user.password;

                    await axios.put(`/users/${res.data.localId}.json`, {...user})
                    .then(() => {
                        status.message = "Sucesso! Usuário criado com sucesso!";
                        status.code = 200;
                    })
                    .catch(err => {

                        status.message = "Opsssss, tivemos algum problema ao registrar o token! (U101)";
                        status.code = 400;
                    })
                }
                
            })
            .catch(async (err) => {

                status.message = "Opsssss, tivemos algum problema ao registrar o usuário! Tente utilizar outro e-mail! (U102)";
                status.code = 400;
            });
 
            return status;
        }

        // erro's code U200 to U299
        async function login (mail, password)
        {
            let status = {
                code: "",
                message: "",
                user: {
                    id_user: "",
                    avatar: "",
                    cellphone: "",
                    is_adm: "",
                    status: "",
                    name: "",
                    mail: "",
                    password: password,
                }
            }
            
            await auth.signInWithEmailAndPassword(mail, password)
            .then(async (userCredential) => {
                
                let user = userCredential.user;

                if (user.uid) 
                {
                    await auth.currentUser
                    .getIdToken()
                    .then(async (idToken) => {

                        status.user.token = idToken;
                        status.user.refreshToken = auth.currentUser.refreshToken;
                        status.user.id_user = user.uid;

                        await axios.get(`/users/${user.uid}.json?auth=${status.user.token}`)
                        .then(res => {
    
                            status.user.name = res.data.name;
                            status.user.avatar = res.data.avatar;
                            status.user.is_adm = res.data.is_adm;
                            status.user.status = res.data.status;
                            status.user.name = res.data.name;
                            status.user.mail = res.data.mail;
    
                            status.message = "Sucesso!";
                            status.code = 200;                        
                        })
                        .catch(err => {

                            status.message = "Opsssss, tivemos algum problema ao obter os dados do usuário! (U201)";
                            status.code = 400;
                        });

                    })
                    .catch(function(err) {

                        status.message = "Opsssss, tivemos algum problema ao identificação o sistema! (U202)";
                        status.code = 400;
                    });
                }
            })
            .catch((err) => {

                status.message = "Opsssss, e-mail e/ou senha errado(s)! Por favor, confira e tente novamente! (U203)";
                status.code = 400;
            });

            return status;
        }

        // erro's code U300 to U399
        async function recovery (mail)
        {
            let status = {
                code: "",
                message: "",
            }

            await axios.post(`${changeBaseURL}?key=${API_KEY_FIREBASE}`, 
            {
                "requestType": "PASSWORD_RESET",
                "email": mail
            })
            .then(res => {
                status.code = 200;
                status.message = "Sucesso!"                
            })
            .catch(err => {

                status.code = 400;
                status.message = "Opsssss, tivemos algum problema ao enviar o link de recuperação! (U301)"
            })

            return status;
        }

        // erro's code U600 to U699
        async function update (idUser, token, newData)
        {
            let status = {
                code: "",
                message: ""
            }
            
            await axios.patch(`/users/${idUser}.json?auth=${token}`, {
                ...newData
            })
            .then(res => {
                
                if (res.data)
                {
                    status.code = 200;
                    status.message = "Sucesso!";        
                }
            })
            .catch(err => {

                status.code = 400;
                status.message = "Opsssss, tivemos algum problema ao atualizar os dados do usuário! (U600)"
            })

            return status;
        }

        // erro's code U700 to U799
        async function getAll (token)
        {
            let status = {
                code: "",
                message: "",
                data: null
            }
            
            await axios.get(`/users/.json?auth=${token}`)
            .then(res => {
                if (res.data)
                {
                    status.code = 200;
                    status.message = "Sucesso!";       
                    status.data = res.data;         
                }
            })
            .catch(err => {

                status.code = 400;
                status.message = "Opsssss, tivemos algum problema ao recuperar os usuários! Tente fazer o login novamente! (U700)"
            })

            return status;
        }

        // erro's code U800 to U899
        async function refreshToken (refreshToken)
        {
            let status = {
                code: "",
                message: "",
                data: null
            }

            await axios.post(`${authRefreshBaseURL}?key=${API_KEY_FIREBASE}`, {
                grant_type: "refresh_token",
                refresh_token: refreshToken
            })
            .then(res => {                
            
                    status.code = 200;
                    status.message = "Sucesso!";       
                    status.data = res;         
                
            })
            .catch(err => {

                status.code = 400;
                status.message = "Opsssss, tivemos algum problema ao renovar o token! Tente fazer o login novamente! (U801)"
            })

            return status;
        }

        return {
            create,
            login,
            recovery,
            update,
            getAll,
            refreshToken
        }
    }

    // Erro's code Q
    question ()
    {
        // erro's code Q100 to Q199
        async function getAll (token)
        {
            let status = {
                code: "",
                message: "",
                data: null
            }
            
            await axios.get(`/questions/.json?auth=${token}`)
            .then(res => {
                
                if (res.data)
                {
                    status.code = 200;
                    status.message = "Sucesso!";       
                    status.data = res.data;         
                }
            })
            .catch(err => {

                status.code = 400;
                status.message = "Opsssss, tivemos algum problema ao recuperar as questões! Tente fazer o login novamente! (Q100)"
            })

            return status;
        }

        // erro's code Q200 to Q299
        async function create (question, token) 
        {
            let status = {
                code: "",
                message: ""
            }

            await axios.patch(`/questions/${question.question_number}.json?auth=${token}`, {...question} )
            .then(async (res) => {
                status.code = 200;
                status.message = "Sucesso!";
           
            })
            .catch(err => {

                status.message = "Opsssss, tivemos algum problema ao inserir a questão! Tente efetuar o login novamente! (Q200)";
                status.code = 400;
            });

            return status;
        }

        // erro's code Q300 to Q399
        async function removeProperty (question, property, token) 
        {
            let status = {
                code: "",
                message: ""
            }

            await axios.delete(`/questions/${question.question_number}/${property}.json?auth=${token}`)
            .then(async (res) => {
                status.code = 200;
                status.message = "Sucesso!";
           
            })
            .catch(err => {

                status.message = "Opsssss, tivemos algum problema ao atualizar a questão! Tente efetuar o login novamente! (Q300)";
                status.code = 400;
            });

            return status;
        }

        // erro's code Q400 to Q499
        async function remove (number, token) 
        {
            let status = {
                code: "",
                message: ""
            }

            await axios.delete(`/questions/${number}.json?auth=${token}`)
            .then(async (res) => {
                status.code = 200;
                status.message = "Sucesso!";
           
            })
            .catch(err => {

                status.message = "Opsssss, tivemos algum problema ao excluir a questão! Tente efetuar o login novamente! (Q400)";
                status.code = 400;
            });

            return status;
        }

        return {
            getAll,
            create,
            remove,
            removeProperty
        }
    }

    // Erro's code S
    simulated ()
    {
        // erro's code S100 to S199
        async function getAll (token)
        {
            let status = {
                code: "",
                message: "",
                data: null
            }
            
            await axios.get(`/simulated/.json?auth=${token}`)
            .then(res => {
                
                if (res.data)
                {
                    status.code = 200;
                    status.message = "Sucesso!";       
                    status.data = res.data;         
                }
            })
            .catch(err => {

                status.code = 400;
                status.message = "Opsssss, tivemos algum problema ao recuperar os simulados! Tente fazer o login novamente! (S100)"
            })

            return status;
        }

        return {
            getAll
        }
    }

    // Erro's code M
    material ()
    {
        // erro's code M100 to M199
        async function getAll (token)
        {
            let status = {
                code: "",
                message: "",
                data: null
            }
            
            await axios.get(`/materials/.json?auth=${token}`)
            .then(res => {
                status.code = 200;
                status.message = "Sucesso!";       
                
                if (res.data) { status.data = res.data; }
            })
            .catch(err => {

                status.code = 400;
                status.message = "Opsssss, tivemos algum problema ao recuperar os materiais! Tente fazer o login novamente! (M100)"
            })

            return status;
        }

        // erro's code M200 to M299
        async function create (material, token) 
        {
            let status = {
                code: "",
                message: ""
            }

            await axios.post(`/materials.json?auth=${token}`, {...material} )
            .then(async (res) => {
                status.code = 200;
                status.message = "Sucesso!";
            
            })
            .catch(err => {

                status.message = "Opsssss, tivemos algum problema ao inserir o material! Tente efetuar o login novamente! (M200)";
                status.code = 400;
            });

            return status;
        }

        // erro's code M300 to M399
        async function update (id, material, token) 
        {
            let status = {
                code: "",
                message: ""
            }

            await axios.patch(`/materials/${id}.json?auth=${token}`, {...material} )
            .then(async (res) => {
                status.code = 200;
                status.message = "Sucesso!";
            
            })
            .catch(err => {

                status.message = "Opsssss, tivemos algum problema ao atualizar o material! Tente efetuar o login novamente! (M300)";
                status.code = 400;
            });

            return status;
        }

        // erro's code M400 to M499
        async function remove (id, token) 
        {
            let status = {
                code: "",
                message: ""
            }

            await axios.delete(`/materials/${id}.json?auth=${token}`)
            .then(async (res) => {
                status.code = 200;
                status.message = "Sucesso!";
           
            })
            .catch(err => {

                status.message = "Opsssss, tivemos algum problema ao excluir o material! Tente efetuar o login novamente! (M400)";
                status.code = 400;
            });

            return status;
        }
        return {
            create,
            getAll,
            update,
            remove
        }
    }

    // Erro's code G
    general ()
    {
        // erro's code G100 to G199
        async function uploadFile (file, fileName, folder)
        {
            return new Promise((resolve, reject) => {
                
                storage.ref(`/main/${folder}/${fileName}`).put(file)
                .on(
                    "state_changed",
                    snapshot => {},
                    async (err)  => {
                        let status = {
                            code: 400,
                            message: "",
                            url_image: ""
                        };
    

                        status.message = "Opsssss, tivemos algum problema ao fazer o upload da imagem! (G100)";
                        status.code = 400;
                        reject(status)
                    },
                    () => {
                        storage
                        .ref(`/main/${folder}`)
                        .child(fileName)
                        .getDownloadURL()
                        .then(async (url) => {
                            let status = {
                                code: 400,
                                message: "",
                                url_image: ""
                            }
    
                            status.code = 200;
                            status.message = "Sucesso!";
                            status.url_image = url;
                            resolve(status);
                        });
                    }
                );

            });
        }

        // erro's code G200 to G299
        async function system (token)
        {
            let status = {
                code: "",
                message: "",
                data: null
            }
            
            await axios.get(`/system/.json?auth=${token}`)
            .then(res => {
                
                if (res.data)
                {
                    status.code = 200;
                    status.message = "Sucesso!";       
                    status.data = res.data;         
                }
            })
            .catch(err => {

                status.code = 400;
                status.message = "Opsssss, tivemos algum problema ao recuperar as informações do sistema! Tente fazer o login novamente! (G200)"
            })

            return status;
        }

        // erro's code G300 to G399
        async function sendNotification (devices, targets, token)
        {
            let status = {
                code: "",
                message: "",
            }

            await axios({
                url: "sendNotification",
                baseURL: "https://sendnotification-cokig3dypq-uc.a.run.app",
                method: "post",
                data: { devices }
            })
            .then(async (res) => {
                status.code = 200;
                status.message = "Sucesso!";
            })
            .catch(err => {

                status.message = "Opsssss, tivemos algum problema ao fazer o envio da(s) notificação(ões)! (G300)";
                status.code = 400;
            });

            for (let i = 0; i < targets.length; i++)
            {
                let target = targets[i];
                let id = target.id;

                delete target.id;
                await axios.post(`/users/${id}/notifications/.json?auth=${token}`, {...target});
            }

            return status;
        }

        // erro's code G400 to G499
        async function updateSystem (data, token)
        {
            let status = {
                code: "",
                message: ""
            }

            await axios.patch(`/system.json?auth=${token}`, {...data} )
            .then(async (res) => {
                status.code = 200;
                status.message = "Sucesso!";
            })
            .catch(err => {

                status.message = "Opsssss, tivemos algum problema ao atualizar as informações do sistema! Tente efetuar o login novamente! (G400)";
                status.code = 400;
            });

            return status;
        }
        
        return {
            system,
            uploadFile,
            updateSystem,
            sendNotification,
        }
    }
}

export default API;