import React from "react"; 
import "./Box.css";

export default function Box (props){
    return (
        <div className="box" >
            <h5 className="subtitle">{props.subtitle}</h5>
            {props.children}
        </div>
    )
}